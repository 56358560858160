import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import Baja from "../../Baja";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import moment from 'moment';

function ReporteCompras() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_COMPRAS_ACUMULADOS = process.env.REACT_APP_URL_COMPRAS_ACUMULADOS;

  // const URL_LINEAS = process.env.REACT_APP_URL_LINEAS;
  // const URL_MARCAS = process.env.REACT_APP_URL_MARCAS;
  // const URL_FAMILIAS = process.env.REACT_APP_URL_FAMILIAS;
  // const URL_COLECCIONES = process.env.REACT_APP_URL_COLECCIONES;


  let hoy = new Date()
  let inicio = new Date()
  inicio.setDate(inicio.getDate()-30);
 
  let endDate   = moment(hoy).format("YYYY-MM-DD"); 
  let startDate   = moment(inicio).format("YYYY-MM-DD");

  const [comprasAcumulados, setComprasAcumulados] = useState([]);

  const [selectedArticulo, setSelectedArticulo] = useState("");

  const [selectedFechaInicio, setSelectedFechaInicio] = useState(startDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);

  const [totalTotal, setTotalTotal] = useState(0);
  // const [lineas, setLineas] = useState([]);
  // const [selectedLineaFiltro, setSelectedLineaFiltro] = useState("");
  // const [marcas, setMarcas] = useState([]);
  // const [selectedMarca, setSelectedMarca] = useState("");
  // const [familias, setFamilias] = useState([]);
  // const [selectedFamiliaFiltro, setSelectedFamiliaFiltro] = useState("");
  // const [colecciones, setColecciones] = useState([]);
  // const [selectedColeccion, setSelectedColeccion] = useState("");
  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [activo, setActivo] = useState("");

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useEffect(() => {

    axios
      .get(`${URL_COMPRAS_ACUMULADOS}Fechas/${selectedFechaInicio}/${selectedFechaFin}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allCompras = response.data;
        let arrayTabla = allCompras
        .sort((a, b) => (a.codigo > b.codigo ? 1 : -1))
        .map((a) => {
          return {
            codigo: a.articulos[0].codigo,
            nombre: a.articulos[0].nombre,
            cantidad: a.cantidad,
          }
        }) 
        .filter(function (el) {
          return el != null;
        });
        let dataFinal = Object.values(arrayTabla);
        let agrupado = dataFinal.reduce(function (groups, item) {
          const val = item["codigo"] + item["nombre"];
          groups[val] = groups[val] || {
            codigo: item.codigo,
            nombre: item.nombre,
            cantidad: 0,
          };
          groups[val].cantidad += item.cantidad;
          groups[val].codigo = item.codigo;
          groups[val].nombre = item.nombre;
          return groups;
        }, []);
        let dataFinalAgrupado = Object.values(agrupado);
       
        setComments(dataFinalAgrupado);
      })
      .catch((err) => {
        console.log(err);
      });
    // axios
    //   .get(URL_ARTICULOS, {
    //     headers: {
    //       Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
    //     },
    //   })
    //   .then((response) => {
    //     let allArticulos = response.data;
    //     setArticulos(allArticulos);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    // axios
    //   .get(URL_LINEAS, {
    //     headers: {
    //       Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
    //     },
    //   })
    //   .then((response) => {
    //     let allLineas = response.data;
    //     setLineas(allLineas);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });

    // axios
    //   .get(URL_MARCAS, {
    //     headers: {
    //       Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
    //     },
    //   })
    //   .then((response) => {
    //     let allMarcas = response.data;
    //     setMarcas(allMarcas);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });

    // axios
    //   .get(URL_FAMILIAS, {
    //     headers: {
    //       Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
    //     },
    //   })
    //   .then((response) => {
    //     let allFamilias = response.data;
    //     setFamilias(allFamilias);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });

    // axios
    //   .get(URL_COLECCIONES, {
    //     headers: {
    //       Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
    //     },
    //   })
    //   .then((response) => {
    //     let allColecciones = response.data;
    //     setColecciones(allColecciones);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });

  }, [selectedFechaInicio, selectedFechaFin]);

  function PDFTabla() {
    let total_cantidad = 0;

    const data = comments
      .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
      .map((a) => {
        if (
          (selectedArticulo == 0 || selectedArticulo == a.idArticulo) &&
          // (selectedLineaFiltro == 0 || selectedLineaFiltro == a.idLineas) &&
          // (selectedMarca == 0 || selectedMarca == a.idMarcas) &&
          // (selectedFamiliaFiltro == 0 ||
          //   selectedFamiliaFiltro == a.idFamilias) &&
          // (selectedColeccion == 0 || selectedColeccion == a.idColecciones) &&
          (activo == 0 || activo == a.is_active)
        ) {
          total_cantidad = parseFloat(total_cantidad) + parseFloat(a.cantidad);
          return [
            a.codigo,
            a.nombre,
            // a.lineas,
            // a.marcas,
            // a.familias,
            // a.colecciones,
            new Intl.NumberFormat("en-US").format(a.cantidad),
          ];
        }
      });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 25, 25);
    doc.text(`Listado Pendiente Recibir`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Codigo",
          "Nombre",
          "Cantidad",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          "",
          "Total",
          new Intl.NumberFormat("en-US").format(total_cantidad),
        ],
      ],
      showFoot: "lastPage",
    });
    window.open(doc.output("bloburl"), "_blank");
//doc.save(`PendRecibir.pdf`);
  }

  function excel() {
    const dataExcel = comments.map((a) => {
      if (
        (selectedArticulo == 0 || selectedArticulo == a.idArticulo) &&
          // (selectedLineaFiltro == 0 || selectedLineaFiltro == a.idLineas) &&
          // (selectedMarca == 0 || selectedMarca == a.idMarcas) &&
          // (selectedFamiliaFiltro == 0 ||
          //   selectedFamiliaFiltro == a.idFamilias) &&
          // (selectedColeccion == 0 || selectedColeccion == a.idColecciones) &&
          (activo == 0 || activo == a.is_active)
      ) {
        return {
          Codigo: a.codigo,
          Nombre: a.nombre,
          // Linea: a.lineas,
          // Marca: a.marcas,
          // Familia: a.familias,
          // Coleccion: a.colecciones,
          Cantidad: a.cantidad,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ReporteCompras";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ReporteCompras",
        sheetFilter: [
          "Codigo",
          // "Nombre",
          // "Linea",
          // "Marca",
          // "Familia",
          // "Coleccion",
          "Cantidad",
        ],
        sheetHeader: [
          "Codigo",
          // "Nombre",
          // "Linea",
          // "Marca",
          // "Familia",
          // "Coleccion",
          "Cantidad",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    let total_cantidad = 0;
    let total_recibido = 0;
    let total_pendiente_recibir = 0;

    const data = comments
      .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
      .map((a) => {
        if (
          (selectedArticulo == 0 || selectedArticulo == a.idArticulo) &&
          // (selectedLineaFiltro == 0 || selectedLineaFiltro == a.idLineas) &&
          // (selectedMarca == 0 || selectedMarca == a.idMarcas) &&
          // (selectedFamiliaFiltro == 0 ||
          //   selectedFamiliaFiltro == a.idFamilias) &&
          // (selectedColeccion == 0 || selectedColeccion == a.idColecciones) &&
          (activo == 0 || activo == a.is_active)
        ) {
          total_cantidad = parseFloat(total_cantidad) + parseFloat(a.cantidad);
          return [
            a.codigo,
            a.nombre,
            // a.lineas,
            // a.marcas,
            // a.familias,
            // a.colecciones,
            new Intl.NumberFormat("en-US").format(a.cantidad),
          ];
        }
      });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 25, 25);
    doc.text(`Listado Pendiente Recibir`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Codigo",
          "Nombre",
          // "Linea",
          // "Marca",
          // "Familia",
          // "Coleccion",
          "Cantidad",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          "",
          "Total",
          new Intl.NumberFormat("en-US").format(total_cantidad),
        ],
      ],
      showFoot: "lastPage",
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado PendRecibir",
          email: mailTo,
          fileName: "ReporteCompras.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de PendRecibirs.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  const headers = [
    { name: "Codigo", field: "codigo", sortable: true },
    { name: "Nombre", field: "nombre", sortable: true },
    // { name: "Linea", field: "lineas", sortable: true },
    // { name: "Marca", field: "marcas", sortable: true },
    // { name: "Familia", field: "familias", sortable: true },
    // { name: "Coleccion", field: "colecciones", sortable: true },
    { name: "Cantidad", field: "cantidad", sortable: true },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.codigo.toLowerCase().includes(search.toLowerCase()) ||
          comment.nombre.toLowerCase().includes(search.toLowerCase()) ||
          // comment.lineas.toLowerCase().includes(search.toLowerCase()) ||
          // comment.marcas.toLowerCase().includes(search.toLowerCase()) ||
          // comment.familias.toLowerCase().includes(search.toLowerCase()) ||
          // comment.colecciones.toLowerCase().includes(search.toLowerCase()) ||
          comment.cantidad.toString().includes(search)
      );
    }

    // if (selectedLineaFiltro) {
    //   computedComments = computedComments.filter((e) =>
    //     e.idLineas.includes(selectedLineaFiltro)
    //   );
    // }
    // if (selectedMarca) {
    //   computedComments = computedComments.filter((e) =>
    //     e.idMarcas.includes(selectedMarca)
    //   );
    // }
    if (selectedArticulo) {
      computedComments = computedComments.filter((e) =>
        e.idArticulo.includes(selectedArticulo)
      );
    }
    // if (selectedFamiliaFiltro) {
    //   computedComments = computedComments.filter((e) =>
    //     e.idFamilias.includes(selectedFamiliaFiltro)
    //   );
    // }
    // if (selectedColeccion) {
    //   computedComments = computedComments.filter((e) =>
    //     e.idColecciones.includes(selectedColeccion)
    //   );
    // }


    setTotalItems(computedComments.length);
    let can = computedComments.map((c) => parseFloat(c.cantidad));
    let TCan = can.reduce((t, cantidad, index) => t + cantidad, 0);
    setTotalTotal(TCan);
    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "cantidad" 
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "cantidad")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "cantidad")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedArticulo,
    // selectedLineaFiltro,
    // selectedMarca,
    // selectedFamiliaFiltro,
    // selectedColeccion,
  ]);

  function limpiarSearch() {
    setSearch("");
    setSelectedArticulo("");
    // setSelectedLineaFiltro("");
    // setSelectedMarca("");
    // setSelectedFamiliaFiltro("");
    // setSelectedColeccion("");
  }

  let totalCantidad = 0;

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_compras ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              <Button
                size="sm"
                href="/MenuCompras"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Reporte Compras</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>

          <h3 align="center">Reporte Compras</h3>
          <div className="row">
            <div className="col-md-10">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-1 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
            <div className="col-md-1 flex-row-reverse">
              <Button
                size="sm"
                className="btn"
                color="info"
                onClick={limpiarSearch}
              >
                Limpiar
              </Button>
            </div>
          </div>
          <Row>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Inicio</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  setSelectedFechaInicio(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Fin</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaFin}
                required
                onChange={(e) => {
                  setSelectedFechaFin(e.target.value);
                }}
              />
            </Col>

          
          </Row>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
        {/*     <tr>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedArticulo}
                  onChange={(e) => {
                    setSelectedArticulo(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {articulos
                    .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.codigo}</option>;
                    })}
                </Input>
              </td> 
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedArticulo}
                  onChange={(e) => {
                    setSelectedArticulo(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {articulos
                    .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.nombre}</option>;
                    })}
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedLineaFiltro}
                  onChange={(e) => {
                    setSelectedLineaFiltro(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {lineas
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedMarca}
                  onChange={(e) => {
                    setSelectedMarca(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {marcas
                    .sort((a, b) => (a > b ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </td>

              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedFamiliaFiltro}
                  onChange={(e) => {
                    setSelectedFamiliaFiltro(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {familias
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedColeccion}
                  onChange={(e) => {
                    setSelectedColeccion(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {colecciones
                    .sort((a, b) => (a > b ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </td>

            </tr>*/}
            <tbody>
              {commentsData.map((a) => {
                totalCantidad = totalCantidad + a.cantidad;
                {
                  return (
                    <tr>
                      <td>{a.codigo}</td>
                      <td>{a.nombre}</td>
                      {/* <td>{a.lineas}</td>
                      <td>{a.marcas}</td>
                      <td>{a.familias}</td>
                      <td>{a.colecciones}</td> */}
                      <td>
                        {new Intl.NumberFormat("en-US").format(a.cantidad)}
                      </td>
                    </tr>
                  );
                }
              })}
              <tr>
                <td></td>
                <td className="negrita">TOTAL</td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US").format(totalTotal)}
                </td>
              </tr>
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}


      {loader}
    </>
  );
}

export default ReporteCompras;
